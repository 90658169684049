import { TOPPER_ENVIRONMENTS } from '@uphold/topper-web-sdk';

function mapToEnum(envValue: string | undefined, enumType: any): any {
  if (!envValue) return undefined;
  return enumType[envValue.toUpperCase()] || enumType.PRODUCTION;
}

const appConfig = {
  topperEnvironment: mapToEnum(
    process.env.REACT_APP_TOPPER_ENVIRONMENT,
    TOPPER_ENVIRONMENTS
  ),
  topperServiceUrl: process.env.REACT_APP_TOPPER_SERVICE_URL,
};

export default appConfig;
