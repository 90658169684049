import axios from 'axios';
import appConfig from '../config';

export const fetchBootstrapToken = async (): Promise<string> => {
  try {
    const response = await axios.get(
      `${appConfig.topperServiceUrl}/topper/bootstrap/token`
    );
    return response.data.token;
  } catch (error) {
    console.error('Failed to retrieve bootstrap token', error);
    throw new Error('Failed to retrieve bootstrap token');
  }
};
