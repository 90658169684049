import React, { useEffect, useRef } from 'react';
import { TopperWebSdk, TOPPER_VARIANTS, TOPPER_THEMES, TOPPER_EVENTS } from '@uphold/topper-web-sdk';
import appConfig from '../config';
interface TopperIntegrationIFrameProps {
  bootstrapToken: string;
}

const TopperIntegrationIFrame: React.FC<TopperIntegrationIFrameProps> = ({ bootstrapToken }) => {
  const topperIframeRef = useRef<HTMLIFrameElement>(null); // Change to HTMLIFrameElement

  useEffect(() => {
    if (!bootstrapToken) {
      console.error('Bootstrap token is required for Topper initialization.');
      return;
    }

    const topper = new TopperWebSdk({
      variant: TOPPER_VARIANTS.IFRAME,
      theme: TOPPER_THEMES.DARK,
      environment: appConfig.topperEnvironment,
    });

    if (topperIframeRef.current) {
      topper.initialize({
        bootstrapToken,
        iframeElement: topperIframeRef.current,
      });

      topper.on(TOPPER_EVENTS.ORDER_PLACED, ({ data }) => {
        console.log('Order placed:', data);
      });
    }

    return () => {
    };
  }, [bootstrapToken]);

  return (
    <iframe
      ref={topperIframeRef}
      title="Topper Integration"
      style={{ height: '100%', width: '100%', border: 'none' }}
      allowFullScreen
    />
  );
};

export default TopperIntegrationIFrame;