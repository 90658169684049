import axios from 'axios';

export const fetchUserCountry = async (): Promise<string> => {
  try {
    const response = await axios.get('https://ipapi.co/json/');
    return response.data.country_code;
  } catch (error) {
    console.error("Failed to retrieve user's country", error);
    throw new Error("Failed to retrieve user's country");
  }
};

export const fetchSupportedCountries = async (): Promise<string[]> => {
  try {
    const response = await axios.get('https://api.topperpay.com/countries');
    const countries = response.data.countries.map(
      (country: { alpha2: string }) => country.alpha2
    );
    return countries;
  } catch (error) {
    console.error('Failed to retrieve supported countries', error);
    throw new Error('Failed to retrieve supported countries');
  }
};

export {};
