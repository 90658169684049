// src/TopperContainer.tsx
import React, { useEffect, useState } from 'react';
import { fetchUserCountry, fetchSupportedCountries } from '../services/CountryService';
import { fetchBootstrapToken } from '../services/TopperService';
import TopperIntegrationIFrame from './TopperIntegrationIframe';

const TopperContainer: React.FC = () => {
  const [isCountrySupported, setIsCountrySupported] = useState<boolean | null>(null);
  const [bootstrapToken, setBootstrapToken] = useState<string>('');
  const [loadingDots, setLoadingDots] = useState('');


  useEffect(() => {
    const initializeTopper = async () => {
      try {
        const userCountry = await fetchUserCountry();
        const supportedCountries = await fetchSupportedCountries();

        if (supportedCountries.includes(userCountry)) {
          const token = await fetchBootstrapToken();
          setBootstrapToken(token);
          setIsCountrySupported(true);
        } else {
          setIsCountrySupported(false);
        }
      } catch (error) {
        console.error("Error during Topper initialization: ", error);
        setIsCountrySupported(false); // Default to unsupported on error
      }
    };

    initializeTopper();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoadingDots(prevDots => {
        // Cycle through "", ".", "..", "..."
        return prevDots.length < 3 ? prevDots + "." : "";
      });
    }, 500);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  return (
    <>
      {isCountrySupported === null && <p>Checking country support{loadingDots}</p>}
      {isCountrySupported === false && <p>Sorry, your country is not supported.</p>}
      {isCountrySupported && bootstrapToken && <TopperIntegrationIFrame bootstrapToken={bootstrapToken} />}
    </>
  );
};

export default TopperContainer;
